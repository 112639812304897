import { DEFAULT_DISTANCE } from 'utils/constants';

const generateAdvertisementsPayload = ({
  term,
  isNearby,
  distance,
  province,
  label_ids,
  settlement,
  category_id,
  selectedCity,
  userLocation,
  provinceCode,
  selectedProvince,
}) => {
  const hasSearchBody =
    term ||
    isNearby ||
    distance ||
    settlement ||
    selectedCity ||
    selectedProvince ||
    province;

  let correctCityName;

  if (settlement) {
    correctCityName = settlement;
  } else if (selectedCity) {
    correctCityName = selectedCity;

    if (provinceCode) {
      correctCityName = `${selectedCity}-${provinceCode}`;
    }
  }

  return {
    ...(category_id && { category_id }),
    ...(!!label_ids?.length && { label_ids }),
    ...(hasSearchBody && {
      search: {
        ...(term && { term }),
        ...(distance && {
          location: {
            radius: distance || DEFAULT_DISTANCE,
          },
        }),
        ...(isNearby &&
          userLocation && {
            location: {
              ...(distance && { radius: distance }),
              lat: userLocation.lat,
              lng: userLocation.lng,
            },
          }),
        ...((selectedCity || settlement) && {
          term: correctCityName,
          type: 'settlement',
        }),
        ...((selectedProvince || province) && {
          term: selectedProvince || province,
          type: 'province',
        }),
      },
    }),
  };
};

export default generateAdvertisementsPayload;
