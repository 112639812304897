import { makeStyles } from '@material-ui/core/styles';

import variables from '../../../static/styles/jss/abstracts/variables';

const extendDftStyles = {
  paddingBottom: 20,
  '& > p': {
    color: 'rgba(0,0,0,0.6)',
    '& span': {
      textDecoration: ['underline', '!important'],
      cursor: 'pointer',
    },
  },
};
export default makeStyles({
  'login-form--block': {
    ...extendDftStyles,
    paddingBottom: 20,
    '& .forgot-your-pass': {
      color: 'rgba(0,0,0,0.6)',
      fontSize: '13px',
      cursor: 'pointer',
    },
  },

  'register-form--block': {
    ...extendDftStyles,

    '& .customSelectBorder .select': {
      border: '1px solid #D8D8D8',
      '& .register-checkbox': {
        alignItems: 'flex-start',
        '& .input-element-wrapper': {
          paddingTop: '4px',
          '& .checkbox-wrap::before': {
            borderColor: '#fff !important',
          },
        },
      },
    },
  },

  'forgot-password--block': {
    ...extendDftStyles,
  },
  'confirm-email--block': {
    ...extendDftStyles,
  },
  'send-message--block': {
    ...extendDftStyles,
    '& p': {
      '& span': {
        marginRight: '10px',
      },
    },
  },
  'create-ad--block': {
    ...extendDftStyles,
  },
  'choose-ad--block': {
    ...extendDftStyles,
    '& b': {
      color: '#000',
    },
    '& .choose-ad-price-tag': {
      marginTop: '-10px',
    },
    '& ul': {
      padding: '0',
      marginTop: '16px',
      borderBottom: `1px solid ${variables.$greyTxtColor}`,
      maxHeight: '229px',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '6px',
        backgroundColor: '#EFEFEF',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '10px',
        background: variables.$helperTextColor,
      },
      '& li': {
        alignItems: 'center',
        display: 'flex',
        padding: '20px 0',
        position: 'relative',
        cursor: 'pointer',
        borderTop: `1px solid ${variables.$greyTxtColor}`,
        '& .icon-Chevron---Right': {
          position: 'absolute',
          right: 0,
        },
      },
    },
  },
  'item--block': {
    marginLeft: '5px',
    '& p': {
      fontSize: '13px',
      color: 'rgba(0,0,0,0.6)',
      margin: '0',
    },
    '& h3': {
      fontSize: '14px',
      color: '#000',
      marginBottom: '0px',
    },
  },
});
